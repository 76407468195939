<template>
  <a-spin :spinning="loading" :tip="loadingTip">
    <div class="tableList">
      <div class="search">
        <div class="search-body">
          <a-form-model layout="inline">
            <a-form-model-item label="文件标题">
              <a-input v-model="search.title" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="来文单位">
              <a-input v-model="search.fromOrg" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="收文日期">
              <a-range-picker @change="onTimeChange" />
            </a-form-model-item>
            <a-form-model-item label="状态">
              <a-select v-model="search.status" placeholder="请选择" allowClear style="width: 120px">
                <a-select-option :value="0">待提交</a-select-option>
                <a-select-option :value="1">已提交</a-select-option>
                <a-select-option :value="2">通过</a-select-option>
                <a-select-option :value="3">不通过</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <p-button type="primary" name="搜索" @click="searchList" />
            </a-form-model-item>
            <a-form-model-item style="float: right">
              <p-button type="primary" :roles="['来文阅办-新增']" style="margin-right: 10px" @click="addOrUpdate(null)"
                name="新增" />
              <a-button type="default" @click="exportData">导出</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <div class="tableItem">
        <a-table rowKey="id" :columns="tableInfo.columns" :data-source="tableInfo.datas"
          :pagination="tableInfo.pagination" @change="onChange" :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: tableInfo.tableHeight }">
          <template slot="status" slot-scope="info">
            <processStatus :processModel="info" />
          </template>
          <template slot="print" slot-scope="text">
            <a-tag color="green" v-if="text == 1"> 已打印 </a-tag>
            <a-tag color="orange" v-if="text == 0"> 未打印 </a-tag>
          </template>
          <span slot="action" slot-scope="action">
            <template v-if="action.creator == currUser">
              <a-button @click="addOrUpdate(action)" size="small" type="primary"
                v-if="action.status == 0 || action.status == 3">修改</a-button>
              <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="changeStatus(action.id, 1)"
                v-if="action.status == 0 || action.status == 3">
                <a-button size="small" type="danger">提交</a-button>
              </a-popconfirm>
              <a-popconfirm title="确定要删除吗？" @confirm="deleteItem(action.id)"
                v-if="action.status == 0 || action.status == 3">
                <a-button size="small" type="danger">删除</a-button>
              </a-popconfirm>
            </template>
            <a-button @click="addOrUpdate(action)" size="small" type="primary"
              v-if="action.processExecuteStatus">审批</a-button>
            <a-button v-if="action.status == 1 || action.status == 2" @click="addOrUpdate(action)" size="small"
              type="default">详情</a-button>
          </span>
        </a-table>
      </div>
      <a-modal :title="editor.title" v-model="editor.visible" :dialog-style="{ top: '20px' }" width="90%"
        :footer="false" destroyOnClose :maskClosable="false">
        <createdocumentread :info="editor.info" @callback="modelCallBack" />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import documentreadApi from "@/api/work/documentread";
import createdocumentread from "./createdocumentread.vue";
const columns = [
  {
    dataIndex: "number",
    align: "center",
    title: "文号",
    ellipsis: true,
  },
  {
    dataIndex: "summary",
    align: "center",
    title: "来文摘要",
    ellipsis: true,
  },
  {
    dataIndex: "fromOrg",
    align: "center",
    title: "来文单位",
    ellipsis: true,
  },
  {
    dataIndex: "urgency",
    align: "center",
    title: "紧急程度",
    width: 100,
    ellipsis: true,
  },
  {
    dataIndex: "confidentiality",
    align: "center",
    title: "密级",
    width: 80,
    ellipsis: true,
  },
  {
    dataIndex: "receiveTime",
    align: "center",
    title: "收文日期",
    width: 140,
  },
  {
    key: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
    title: "传批(阅)状态",
  },
  {
    dataIndex: "print",
    align: "center",
    width: 90,
    scopedSlots: { customRender: "print" },
    title: "打印状态",
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "documentprint",
  data() {
    return {
      loading: false,
      loadingTip: "加载中",
      search: {},
      tableInfo: {
        datas: [],
        columns,
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 20,
          showTotal: (total) => `总共${total}条`,
        },
        tableHeight: window.innerHeight - 330,
      },
      selectRows: [],
      editor: {
        title: "编辑",
        visible: false,
        info: {},
      },
      currUser: localStorage.getItem(this.$commons.User.userName),
    };
  },
  components: {
    createdocumentread,
  },
  mounted() {
    let _this = this;
    if (window.innerWidth >= 1600) {
      _this.tableInfo.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.tableInfo.tableHeight = window.innerHeight - 330;
      if (window.innerWidth >= 1600) {
        _this.tableInfo.pagination.pageSize = 20;
      } else {
        _this.tableInfo.pagination.pageSize = 10;
      }
    };
    this.getPageList();
  },
  methods: {
    changeStatus(id, status) {
      this.loading = true;
      documentreadApi.changeStatus(id, status).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    deleteItem(id) {
      this.loading = true;
      documentreadApi.deleteItem(id).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    exportData() {
      var _this = this;
      _this.loading = true;
      documentreadApi.exportData({ ...this.search }, (d) => {
        _this.loadingTip = "已完成" + d + "%";
        if (d == 100) {
          setTimeout(function () {
            _this.loading = false;
          }, 2000);
        }
      });
    },
    async getPageList() {
      let _this = this;
      let param = {
        ...this.search,
        pageNow: _this.tableInfo.pagination.current,
        pageSize: _this.tableInfo.pagination.pageSize,
      };
      documentreadApi.getList(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.tableInfo.datas = res.data.records;
          _this.tableInfo.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    onTimeChange(date, dateString) {
      this.search.startTime = dateString[0];
      this.search.endTime = dateString[1];
    },
    onChange(page) {
      let _this = this;
      _this.tableInfo.pagination.current = page.current;
      _this.getPageList();
    },
    searchList() {
      let _this = this;
      _this.getPageList();
    },

    addOrUpdate(info) {
      let _this = this;
      _this.editor.visible = true;
      if (info) {
        _this.editor.title = "编辑";
        if (info.status == 1 || info.status == 2) {
          _this.editor.title = "查看";
        }
        _this.editor.info = info;
      } else {
        _this.editor.title = "新增";
        info = {
          id: null,
        };
        _this.editor.info = info;
      }
    },

    modelCallBack(f) {
      this.editor.visible = false;
      this.getPageList();
    },
  },
};
</script>

<style lang="less"></style>